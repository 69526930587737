import { Component, OnInit, OnDestroy,HostListener ,ElementRef, Input, Output, ChangeDetectorRef, EventEmitter, Inject } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ToastrService } from "ngx-toastr";

interface singleCategory {
    _id: string;
    title: string;
    description: string;
    popular: boolean;
    status: number;
    parent: string | null;
    parent_title: string | null;
    order: number;
    subcategory: singleCategory[];
    selected: boolean | false;
    // Add other properties as needed
}

@Component({
    selector: "input-filed",
    templateUrl: "./input.html",
    styleUrls: ["./input.scss"],
})
export class TInputFiled {
    @Input() placeholder: string = "Name";
    @Input() type: string = "string";
    @Input() required: boolean = false;
    @Input() minlength: Number = 0;
    @Input() maxlength: Number = 50;
    @Input() model!: any;
    @Input() pattern: string = ".*";
    

    @Output() modelChange = new EventEmitter<string>();

    inputMode = false;
    private addManager: EventEmitter<boolean> = new EventEmitter();
    SaveButtonToggle = false;
    constructor(private toastr: ToastrService, private cdr: ChangeDetectorRef,private elRef: ElementRef) { }
    ngOnInit() { }

    save(): void { }
    onInputFocus() {
        this.inputMode = true;
    }
    extractIntegers(event: any) {
        if (this.type === 'num') {
            const key = event.key;
            if ((key >= "0" && key <= "9") || key === "Backspace") {
            } else {
                event.preventDefault();
            }
        }
	}
    onInputBlur() {
        // if (!this.inputMode) {
        // 	this.inputMode = false;
        // }
    }
    onInputChange(event: Event): void {
        const input = event.target as HTMLInputElement;
        this.model = input.value;
        this.modelChange.emit(this.model); // Notify parent component
    }
    clearText(event: Event) {
        this.model = "";
        this.inputMode = false;
        event.stopPropagation();
    }
    @HostListener('document:click', ['$event'])
    onOutsideClick(event: Event): void {
        const isClickInside = this.elRef.nativeElement.contains(event.target);
        if (!isClickInside) {
            this.inputMode = false; 
        }
    }
    closeDialoge() {
        // this.dialogRef.close(this.selectedCatArr.length == 0 ? [] : this.data.profession);
    }
}
